<template>
  <div class="roomscomponent">
    <div class="headerrooms">
      <!-- Title -->
      <div class="text">
        <h1 class="mosoqtitle" style="font-weight: bold">
          {{ $t("rooms.title") }}
        </h1>
        <p class="mosoqp">
          {{ $t("rooms.p") }}
        </p>
      </div>
    </div>
    <!-- Rooms -->
    <div class="roomssect">
      <!-- Single -->
      <div class="rooms1">
        <div class="roomscont">
          <div class="imgrooms">
            <img
              src="../../assets/fotos/simple2.jpg"
              class="imgimgroom"
              alt=""
            />
          </div>
          <div class="textrooms">
            <h2 style="margin-bottom: 0%">
              {{ $t("rooms.single.title") }}
            </h2>
            <p style="font-weight: bold; color: #b88b4a">
              $45/{{ $t("rooms.price") }} ¡40% {{ $t("home.rooms.desc") }}!
            </p>
            <p class="">
              {{ $t("rooms.single.description") }}{{ $t("rooms.compl") }}
            </p>
            <b-button
              id="book"
              v-on:click="addItem(topRated[0])"
              :to="{ name: 'Book' }"
              >{{ $t("menu.book") }}</b-button
            >
          </div>
        </div>
      </div>
      <!-- Double -->
      <div class="rooms2">
        <div class="roomscont2">
          <div class="textrooms">
            <h2 style="margin-bottom: 0%">
              {{ $t("rooms.double.title") }}
            </h2>
            <p style="font-weight: bold; color: #b88b4a">
              $55/{{ $t("rooms.price") }} ¡40% {{ $t("home.rooms.desc") }}!
            </p>
            <p class="">
              {{ $t("rooms.double.description") }}{{ $t("rooms.compl") }}
            </p>
            <b-button
              id="book"
              v-on:click="addItem(topRated[1])"
              :to="{ name: 'Book' }"
              >{{ $t("menu.book") }}</b-button
            >
          </div>
          <div class="imgrooms">
            <img
              src="../../assets/fotos/doble1.jpg"
              class="imgimgroom"
              alt=""
            />
          </div>
        </div>
      </div>
      <!-- Matrimonial -->
      <div class="rooms1">
        <div class="roomscont">
          <div class="imgrooms">
            <img
              src="../../assets/fotos/matri1.jpg"
              class="imgimgroom"
              alt=""
            />
          </div>
          <div class="textrooms">
            <h2 style="margin-bottom: 0%">
              {{ $t("rooms.matri.title") }}
            </h2>
            <p style="font-weight: bold; color: #b88b4a">
              $55/{{ $t("rooms.price") }} ¡40% {{ $t("home.rooms.desc") }}!
            </p>
            <p class="">
              {{ $t("rooms.matri.description") }}{{ $t("rooms.compl") }}
            </p>
            <b-button
              id="book"
              v-on:click="addItem(topRated[2])"
              :to="{ name: 'Book' }"
              >{{ $t("menu.book") }}</b-button
            >
          </div>
        </div>
      </div>
      <!-- Triple -->
      <div class="rooms2">
        <div class="roomscont2">
          <div class="textrooms">
            <h2 style="margin-bottom: 0%">
              {{ $t("rooms.triple.title") }}
            </h2>
            <p style="font-weight: bold; color: #b88b4a">
              $70/{{ $t("rooms.price") }} ¡40% {{ $t("home.rooms.desc") }}!
            </p>
            <p class="">
              {{ $t("rooms.triple.description") }}{{ $t("rooms.compl") }}
            </p>
            <b-button
              id="book"
              v-on:click="addItem(topRated[3])"
              :to="{ name: 'Book' }"
              >{{ $t("menu.book") }}</b-button
            >
          </div>
          <div class="imgrooms">
            <img
              src="../../assets/fotos/triple1.jpg"
              class="imgimgroom"
              alt=""
            />
          </div>
        </div>
      </div>
      <!-- Quadruple -->
      <div class="rooms1">
        <div class="roomscont">
          <div class="imgrooms">
            <img
              src="../../assets/fotos/triple1.jpg"
              class="imgimgroom"
              alt=""
            />
          </div>
          <div class="textrooms">
            <h2 style="margin-bottom: 0%">
              {{ $t("rooms.quadruple.title") }}
            </h2>
            <p style="font-weight: bold; color: #b88b4a">
              $85/{{ $t("rooms.price") }} ¡40% {{ $t("home.rooms.desc") }}!
            </p>
            <p class="">
              {{ $t("rooms.quadruple.description") }}{{ $t("rooms.compl") }}
            </p>
            <b-button
              id="book"
              v-on:click="addItem(topRated[4])"
              :to="{ name: 'Book' }"
              >{{ $t("menu.book") }}</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    topRated() {
      return this.$store.state.topRated;
    },
  },
  methods: {
    addItem(items) {
      //   this.$store.commit("addToCart")
      this.$store.dispatch("addToCart", items);
    },
    removeItem(items) {
      this.$store.dispatch("removeItem", items);
    },
  },
};
</script>

<style>
.roomscomponent {
  margin-top: 50px;
}
.headerrooms {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.mosoqtitle {
  font-size: 50px;
}
.rooms1 {
  background-color: #fffbe8;
  padding: 1rem 0;
}
.rooms2 {
  padding: 1rem 0;
}
.roomscont,
.roomscont2 {
  display: flex;
}
.textrooms {
  text-align: center;
  justify-content: center;
}

@media (min-width: 992px) {
  .headerb {
    margin-bottom: 15px;
  }
  .text {
    padding: 0 40px;
  }
  .mosoqp {
    font-size: 20px;
  }
  .roomscont,
  .roomscont2 {
    flex-direction: row;
  }
  .textrooms {
    padding: 0px 30px;
    display: flex;
    flex: 2;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media (min-width: 800px) and (max-width: 991px) {
  .headerb {
    margin-bottom: 15px;
  }
  .textrooms {
    padding: 0px 30px;
    display: flex;
    flex: 3;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .text {
    padding: 0 40px;
  }
  .mosoqp {
    font-size: 19px;
  }
  .imgrooms {
    display: flex;
    flex: 2;
    overflow: hidden;
    align-items: center;
    justify-content: center;
  }
}
/* small-tablet-portrait: min:600px - max:799px; */
@media (min-width: 600px) and (max-width: 799px) {
  .headerb {
    margin-bottom: 15px;
  }
  .text {
    padding: 0 25px;
  }
  .mosoqp {
    font-size: 19px;
  }
  .textrooms {
    padding: 0px 30px;
    display: flex;
    flex: 3;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .roomscont,
  .roomscont2 {
    flex-direction: row;
  }
  .imgrooms {
    display: flex;
    flex: 2;
    overflow: hidden;
    align-items: center;
    justify-content: center;
  }
  .imgrooms img {
    height: 100%;
  }
}
/* mobile-landscape: min:480px - max:599px; */
@media (min-width: 480px) and (max-width: 599px) {
  .headerb {
    margin-bottom: 15px;
  }
  .text {
    padding: 0 25px;
  }
  .mosoqp {
    font-size: 19px;
  }
  .roomscont {
    flex-direction: column;
  }
  .textrooms {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 10px 25px;
    align-items: center;
    justify-content: center;
  }
  .roomscont2 {
    flex-direction: column-reverse;
  }
  .imgrooms {
    flex: 1;
    overflow: hidden;
  }
  .imgrooms img {
    width: 100%;
  }
}
/* mobile-portrait: min:320px - max:479px; */
@media /*(min-width: 320px) and*/ (max-width: 479px) {
  .headerb {
    margin-bottom: 15px;
  }
  .text {
    padding: 10px 25px;
  }
  .mosoqp {
    font-size: 19px;
  }
  .roomscont {
    flex-direction: column;
  }
  .textrooms {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0px 25px;
    align-items: center;
    justify-content: center;
  }
  .roomscont2 {
    flex-direction: column-reverse;
  }
  .imgrooms {
    display: flex;
    flex: 1;
    overflow: hidden;
  }
  .imgrooms img {
    width: 100%;
  }
}
</style>